.popular-categories__container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

#popularcategories {
  color: $grey;
  font-size: 1.125rem;
  text-align: left !important;
}

.fancy-grid {
  margin-left: .6rem;
  margin-right: .6rem;
  max-width: 1440px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media #{$tablet} {
    margin-left: 0;
    margin-right: 0;
  }
  section.recent-searches + &, .search-box-placeholder + & {
    padding-top: 1.5em;
  }
  &:last-of-type {
    margin-bottom: 4rem;
  }

  div.clipper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -.4rem;
    margin-right: -.4rem;
    overflow: hidden;
    @media #{$tablet} {
      margin-left: .2rem;
      margin-right: .2rem;
    }
    @media #{$mobile} {
      margin-left: 0;
      margin-right: 0;
    }

    > article {
      flex-grow: 1;
      margin: .2rem;
      position: relative;
      @media #{$tablet} {
        min-width: 260px;
      }
      @media #{$mobile} {
        min-width: 180px;
      }
      @media only screen and (min-width: 1024px) {
        min-width: 0;
        margin: .4rem;
      }
    
      > a {
        background-position: center center;
        background-size: cover;
        box-sizing: border-box;
        display: none;
        flex: 1;

        &:hover {
          filter: brightness(0.7);
        }

        &.active {
          display: block;
        }
      }
      > div {
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
        a {
          color: white;
          text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.75);
          &:hover {
            color: $green;
          }
        }
      }
      &:hover {
        > div {
          display: block;
        }
      }

    }
  }

  figure {
    height: 14rem;
    position: relative;
    transition-property: height;
    transition-duration: 1s;
    transition-timing-function: ease-in-out;

    @media #{$tablet} {
      height: 14rem;
    }

    img {
      display: none;
      max-width: 100%;
    }

    figcaption {
      background: linear-gradient(-180deg, rgba(0,0,0,0) 0%, rgba(1,1,1,0.7) 70%);
      bottom: 0;
      box-sizing: border-box;
      color: white;
      font-size: rem(16);
      letter-spacing: .01em;
      line-height: 1.5rem;
      padding: 1rem;
      position: absolute;
      width: 100%;
    }
    .category-tag {
      color:#ccc;
      font-size: rem(11);
      font-weight: normal;
      letter-spacing: .02em;
    }
  }
}

#category-listing {
  @include grid-row();
  @include clearfix();
  padding-top: 2.5em;
  text-align: center;

  .mob-col div {
    @include grid(3);
    float: left;
    padding-left: 15px;
    padding-right: 15px;
  }
  a {
    font-size: rem(16);
    font-weight: 500;
  }

  @media #{$small-only} {
    h3 {
      font-size: rem(20);
      margin: 20px 0;
    }
    .mob-col {
      @include grid(12);

      >div {
          @include grid(6);
      }
    }
    ul {
      font-size: 75%;
      margin: 0;
    }
    a {
      font-size: rem(13);
      font-weight: 500;
    }
  }
}